import React, { useEffect, useRef, useState } from 'react';
import BlogPost from './BlogPost';
import './LifeUpdate.css';

const LifeUpdate = () => {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const entryRefs = useRef([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('https://blogposts.aaqilrazak.com/api/blog-posts');
        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  const handlePostClick = (index, contentLength) => {
    if (contentLength > 100) {
      setSelectedPost(selectedPost === index ? null : index);
    }
  };

  return (
    <div className="lifeupdate-container">
      <h1 className="lifeupdate-title">Thoughts</h1>

      <div className="lifeupdate-content">
        {posts.map((post, index) => (
          <BlogPost
            key={post._id}
            post={post}
            index={index}
            selectedPost={selectedPost}
            handlePostClick={handlePostClick}
            entryRef={el => entryRefs.current[index] = el}
          />
        ))}
      </div>
    </div>
  );
};

export default LifeUpdate;
