import React, { useEffect, useState } from 'react';
import { cn } from "./utils";
import './Projects.css';

const SkillCloud = ({ skills }) => {
  const [shiningSkills, setShiningSkills] = useState([]);

  useEffect(() => {
    const shineRandomSkills = () => {
      const newShiningSkills = [];
      skills.forEach((skill, index) => {
        if (Math.random() > 0.8) {
          newShiningSkills.push(index);
        }
      });
      setShiningSkills(newShiningSkills);
    };

    const intervalId = setInterval(shineRandomSkills, 1000);

    return () => clearInterval(intervalId);
  }, [skills]);

  return (
    <div className="flex flex-wrap justify-center items-center gap-4 p-8">
      {skills.map((skill, index) => (
        <div
          key={index}
          className={cn("skill", { shining: shiningSkills.includes(index) })}
        >
          {skill}
        </div>
      ))}
    </div>
  );
};

const Marquee = ({
  className,
  reverse,
  pauseOnHover = false,
  children,
  vertical = false,
  repeat = 4,
  ...props
}) => {
  return (
    <div
      {...props}
      className={cn(
        "group flex overflow-hidden p-4 [--duration:45s] [--gap:1.5rem] [gap:var(--gap)]",
        {
          "flex-row": !vertical,
          "flex-col": vertical,
        },
        className
      )}
    >
      {Array(repeat)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            className={cn("flex shrink-0 justify-around [gap:var(--gap)]", {
              "animate-marquee flex-row": !vertical,
              "animate-marquee-vertical flex-col": vertical,
              "group-hover:[animation-play-state:paused]": pauseOnHover,
              "[animation-direction:reverse]": reverse,
            })}
          >
            {children}
          </div>
        ))}
    </div>
  );
};

const Projects = () => {
  const projectDataRow1 = [
    {
      title: "Verilog Circuit Design",
      image: "/icons/circuit.svg",
      description: "Design and simulate digital circuits using Verilog. Will make public soon.",
    },
    {
      title: "eStay: Hotel Management Software",
      image: "/icons/hotel50.svg",
      link: "https://github.com/AaqilRazak/eStay",
      description:
        "A comprehensive management tool for hotels, including room booking, guest management, and billing.",
    },
    {
      title: "Infiniti CarPlay Retrofit",
      image: "/icons/car.svg",
      description:
        "Integrated a Raspberry Pi into legacy Nissan infotainment systems to run Apple Carplay. - Writeup coming soon.",
    },
  ];

  const projectDataRow2 = [
    {
      title: "AI Security Research",
      image: "/icons/security.svg",
      description: "Ongoing AI Security Research. (WIP)",
    },
    {
      title: "AaqilRazak.com",
      image: "/icons/portfolio.svg",
      link: "https://github.com/AaqilRazak/my-portfolio",
      description: "A quick crash course into JS, React, and other related libraries to create a slick and proefessional page to showcase my technological skills - Will be public soon.",
    },
    {
      title: "Campi: AI Univerity Chatbot",
      image: "/icons/ai.svg",
      description: "A project focused on training Llama 3.1 on university-specific information with API integrations, LLM chaining, and data analytics. (WIP)",
    },
  ];

  const skills = [
    "JavaScript",
    "React.js",
    "Express.js",
    "Spring Boot",
    "MVC",
    "PyTorch",
    "Tensorflow",
    "Docker",
    "Node.js",
    "Python",
    "SQL",
    "Git",
    "C++",
    "PHP",
    "HTML/CSS",
    "Kubernetes",
    "Java",
    "MongoDB",
  ];

  return (
    <div className="projects-container">
      <div className="background-blur"></div>

      {/* Mobile carousel */}
      <div className="carousel-container">
        <div className="carousel">
          {projectDataRow1.concat(projectDataRow2).map((project, index) => (
            <div className="project-card" key={index}>
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={project.image}
                  alt={project.title}
                  className="project-image"
                />
              </a>
              <div className="project-title">{project.title}</div>
              <div className="project-description">{project.description}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="skillcloud-container">
        <SkillCloud skills={skills} />
      </div>

      {/* Desktop Marquee */}
      <div className="marquee-container">
        <div style={{ marginBottom: "15px" }}>
          <Marquee pauseOnHover>
            {projectDataRow1.map((project, index) => (
              <div className="project-card" key={index}>
                <a href={project.link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="project-image"
                  />
                </a>
                <div className="project-title">{project.title}</div>
                <div className="project-description">{project.description}</div>
              </div>
            ))}
          </Marquee>
        </div>
        <Marquee pauseOnHover reverse>
          {projectDataRow2.map((project, index) => (
            <div className="project-card" key={index}>
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={project.image}
                  alt={project.title}
                  className="project-image"
                />
              </a>
              <div className="project-title">{project.title}</div>
              <div className="project-description">{project.description}</div>
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default Projects;