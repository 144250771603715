import React, { useState, useEffect, useRef } from 'react';
import './Home.css';

const phrases = ["Getting", "Striving for"];

const Home = ({ onShutterComplete }) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [phraseIndex, setPhraseIndex] = useState(0);
  const videoRef = useRef(null);
  const shutterRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayedText((prev) =>
        deleting
          ? phrases[phraseIndex].substring(0, prev.length - 1)
          : phrases[phraseIndex].substring(0, prev.length + 1)
      );

      if (!deleting && displayedText === phrases[phraseIndex]) {
        setTimeout(() => setDeleting(true), 2000);
      } else if (deleting && displayedText === "") {
        setDeleting(false);
        setPhraseIndex((prev) => (prev + 1) % phrases.length);
      }
    }, deleting ? 50 : 100);

    return () => clearTimeout(timeout);
  }, [displayedText, deleting, phraseIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setLoading(false);
          return 100;
        }
        return prev + 1;
      });
    }, 30);
  }, []);

  const handleVideoTimeUpdate = () => {
    if (videoRef.current) {
      const timeRemaining = videoRef.current.duration - videoRef.current.currentTime;
      if (timeRemaining <= 2 && timeRemaining > 1) {
        videoRef.current.playbackRate = 0.3;
      }
      if (timeRemaining <= 1 && timeRemaining > 0.5) {
        videoRef.current.playbackRate = 0.2;
      }
      if (timeRemaining <= 0.5) {
        videoRef.current.pause();
      }
    }
  };

  useEffect(() => {
    const shutterElement = shutterRef.current;
    
    if (shutterElement) {
      shutterElement.addEventListener('animationend', onShutterComplete);
    }
  
    return () => {
      if (shutterElement) {
        shutterElement.removeEventListener('animationend', onShutterComplete);
      }
    };
  }, [onShutterComplete]);

  const circumference = 2 * Math.PI * 45;
  const currentPercent = Math.round(((progress - 0) / (100 - 0)) * 100);
  const strokeDasharrayValue = (currentPercent / 100) * circumference;

  return (
    <div className="home-container">
      {loading && (
        <div className="loading-screen">
          <div className="loading-text animated-bounce-out">Aaqil Razak</div>
          <div className="circular-progress">
            <svg
              fill="none"
              className="circular-chart"
              strokeWidth="10"
              viewBox="0 0 100 100"
            >
              <circle
                cx="50"
                cy="50"
                r="45"
                strokeWidth="10"
                strokeDashoffset="0"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="circle-bg"
                style={{
                  stroke: "#FF4500",
                  strokeDasharray: `${circumference} ${circumference}`,
                }}
              />
              <circle
                cx="50"
                cy="50"
                r="45"
                strokeWidth="10"
                strokeDashoffset="0"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="circle"
                style={{
                  stroke: "#FFA500",
                  strokeDasharray: `${strokeDasharrayValue} ${circumference}`,
                  transition: "stroke-dasharray 0.3s ease",
                }}
              />
            </svg>
            <span className="percentage">
              {currentPercent}%
            </span>
          </div>
        </div>
      )}
      {!loading && (
        <div className="home-section">
          <video
            ref={videoRef}
            src={`${process.env.PUBLIC_URL}/videos/home-video.mp4`} 
            autoPlay
            muted
            playsInline
            webkit-playsinline
            onTimeUpdate={handleVideoTimeUpdate}
            className="background-video"
          />
          <div className="overlay"></div>
          <div className="overlay-content">
            <h1 className="static-text">
              <span className="typed-text">{displayedText}</span>
              <span className="cursor">|</span>better every day.
            </h1>
          </div>
          <div className="shutter" ref={shutterRef}></div>
        </div>
      )}
    </div>
  );
};

export default Home;
