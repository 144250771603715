import React, { useState } from 'react';
import './ContactMe.css';
import { FaDownload, FaLinkedin } from 'react-icons/fa';

const ContactMe = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submitStatus, setSubmitStatus] = useState('');
  const [buttonText, setButtonText] = useState("Let's talk!");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.message) {
      setSubmitStatus('error');
      setButtonText('Error');
      setTimeout(() => {
        setSubmitStatus('');
        setButtonText("Let's talk!");
      }, 2000);
      return;
    }

    try {
      const response = await fetch('https://submit.aaqilrazak.com/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitStatus('success');
        setButtonText('Success');
        setFormData({
          name: '',
          email: '',
          message: '',
        });
        setTimeout(() => {
          setSubmitStatus('');
          setButtonText("Let's talk!");
        }, 2000);
      } else {
        setSubmitStatus('error');
        setButtonText('Error');
        setTimeout(() => {
          setSubmitStatus('');
          setButtonText("Let's talk!");
        }, 2000);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
      setButtonText('Error');
      setTimeout(() => {
        setSubmitStatus('');
        setButtonText("Let's talk!");
      }, 2000);
    }
  };

  return (
    <div className="contactme-section">
      <video autoPlay muted loop playsInline className="video-background">
        <source src={`${process.env.PUBLIC_URL}/videos/contact-bg.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="contactme-overlay"></div>
      <div className="contactme-form-container">
        <div className="contactme-text-container">
          <h2>I'm looking for work!</h2>
          <p>
            I will be graduating in December 2024 with my B.S in Computer Science and Applied Mathematics.
            I am very motivated and eager to learn the latest industry trends and contribute with meaningful work. Feel free to reach out directly with the form or connect with me on LinkedIn!
          </p>
          <div className="contactme-buttons">
            <a href="/Resume/Aaqil Razak CV Resume.pdf" download="Aaqil Razak CV Resume.pdf" className="resume-button">
              <FaDownload /> Download Resume
            </a>
            <a href="https://www.linkedin.com/in/aaqil-razak-b940b01a2" target="_blank" rel="noopener noreferrer" className="linkedin-button">
              <FaLinkedin /> Connect on LinkedIn
            </a>
          </div>
        </div>
        <div className="contactme-form">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your name here"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your email here"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="What would you like to say?"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <button type="submit" className={`submit-button ${submitStatus}`}>
              {buttonText} <span>→</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactMe;