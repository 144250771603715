import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const BlogPost = ({ post, index, selectedPost, handlePostClick, entryRef }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: 20 });
    }
  }, [inView, controls]);

  return (
    <motion.div
      className={`journal-entry ${selectedPost === index ? 'expanded' : ''}`}
      ref={(el) => {
        ref(el);
        entryRef(el);
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
      transition={{ duration: 0.3, ease: 'easeOut' }}
      whileHover={{ scale: 1.05, boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)' }}
      onClick={() => handlePostClick(index, post.content.length)}
      style={{
        height: selectedPost === index ? `${entryRef?.current?.scrollHeight + 60}px` : 'auto',
        transition: 'height 0.4s ease-in-out',
      }}
    >
      <div className="entry-date">
        {new Date(post.date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
      </div>
      <div className="entry-title">
        {post.title}
      </div>
      <motion.div
        className="entry-text"
        initial={{ opacity: 1 }}
        animate={{ opacity: selectedPost === index ? 1 : 0.5 }}
        transition={{ duration: 0.4 }}
      >
        {selectedPost === index 
          ? post.content 
          : `${post.content.substring(0, 100)}${post.content.length > 100 ? '...' : ''}`}
      </motion.div>
      {post.content.length > 100 && (
        <motion.div
          className="expand-arrow"
          animate={{ rotate: selectedPost === index ? 180 : 0 }}
          transition={{ duration: 0.4, ease: 'easeInOut' }}
        >
          ↓
        </motion.div>
      )}
    </motion.div>
  );
};

export default BlogPost;
