import React, { useEffect, useRef } from 'react';
import './CustomCursor.css';

const CustomCursor = () => {
  const cursorRef = useRef(null);
  const followerRef = useRef(null);
  const mouseX = useRef(0);
  const mouseY = useRef(0);
  const followerX = useRef(0);
  const followerY = useRef(0);

  useEffect(() => {
    const handleMouseMove = (event) => {
      mouseX.current = event.clientX;
      mouseY.current = event.clientY;
      cursorRef.current.style.left = `${mouseX.current}px`;
      cursorRef.current.style.top = `${mouseY.current}px`;

      const elementUnderCursor = document.elementFromPoint(event.clientX, event.clientY);

      if (elementUnderCursor) {
        if (elementUnderCursor.closest('.home-section')) {
          cursorRef.current.style.backgroundColor = 'white';
          followerRef.current.style.borderColor = 'white';
        } else {
          cursorRef.current.style.backgroundColor = '#FF8C00';
          followerRef.current.style.borderColor = '#FF8C00';
        }
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const updateFollower = () => {
      if (followerRef.current) {
        followerX.current += (mouseX.current - followerX.current) * 0.06;
        followerY.current += (mouseY.current - followerY.current) * 0.06;

        followerRef.current.style.left = `${followerX.current}px`;
        followerRef.current.style.top = `${followerY.current}px`;
      }

      requestAnimationFrame(updateFollower);
    };

    updateFollower(); // Start the animation loop

    return () => {
      // No need to remove any event listener here since we're not adding any in this effect
    };
  }, []);

  return (
    <>
      <div className="custom-cursor" ref={cursorRef}></div>
      <div className="custom-follower" ref={followerRef}></div>
    </>
  );
};

export default CustomCursor;