import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './AboutMe.css';

const words = ["Coffeeholic", "Software Developer", "Tech Enthusiast", "Lifelong Learner", "Motivator", "Collaborative Catalyst"];

const AboutMe = () => {
  const [displayedText, setDisplayedText] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayedText((prev) =>
        deleting
          ? words[wordIndex].substring(0, prev.length - 1)
          : words[wordIndex].substring(0, prev.length + 1)
      );

      if (!deleting && displayedText === words[wordIndex]) {
        setTimeout(() => setDeleting(true), 1000);
      } else if (deleting && displayedText === "") {
        setDeleting(false);
        setWordIndex((prev) => (prev + 1) % words.length);
      }
    }, deleting ? 50 : 100);

    return () => clearTimeout(timeout);
  }, [displayedText, deleting, wordIndex]);

  return (
    <div className="aboutme-section">
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        className="aboutme-image-container"
      >
        <img
          src="/images/aboutme.jpg"
          alt="About Me"
          className="aboutme-image"
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        className="aboutme-text-container"
      >
        <div className="aboutme-text">
          <h2>
            <span className="typed-text">
              {displayedText}
              <span className="cursor">|</span>
            </span>
          </h2>
          <p className="biography">
          I’m a software developer who loves solving problems with code and implementing efficient, scalable solutions. I pride myself on being easy to work with, whether collaborating with a team or leading projects to success. Over the years, I’ve grown my skills from begn able to code solutions to being able to architect systems that optimizing workflows. I’m passionate about learning new technological trends, and I’m always looking for ways to grow and contribute.
          <br />
          <br />
          When I’m not diving deep into code, I stay engaged with a range of interests. Whether I’m on the basketball court, immersed in motorsports and car tuning, or tracking the stock market, I’m always pushing myself to take on new challenges. I believe in daily improvement and rely on my strong work ethic to drive success in both my career and passions.
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default AboutMe;