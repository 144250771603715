import React, { useEffect, useState } from 'react';
import { Link as ScrollLink, Element } from 'react-scroll';
import { Helmet } from 'react-helmet';
import Home from './Home';
import AboutMe from './AboutMe';
import Projects from './Projects';
import LifeUpdate from './LifeUpdate';
import ContactMe from './ContactMe';
import CustomCursor from './CustomCursor';
import './App.css';
import './tailwind.css';
import './CustomCursor.css';

function App() {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolled(offset > 50);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>Aaqil Razak</title>
      </Helmet>

      {/* Include Custom Cursor */}
      <CustomCursor />

      <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
        {/* Desktop Links */}
        <div className="navbar-left">
          <ScrollLink to="aboutme" smooth={true} duration={800}>About</ScrollLink>
          <ScrollLink to="projects" smooth={true} duration={800}>Projects</ScrollLink>
          <ScrollLink to="lifeupdate" smooth={true} duration={800}>Blog</ScrollLink>
        </div>

        {/* Navbar center (name) */}
        <ScrollLink to="home" smooth={true} duration={800} className="navbar-center">
          <div className="name-wrapper">
            AAQIL RAZAK
          </div>
        </ScrollLink>

        {/* Navbar right (Contact button) */}
        <div className="navbar-right">
          <ScrollLink to="contactme" smooth={true} duration={800} className="navbar-button">
            Contact
          </ScrollLink>
        </div>

        {/* Mobile Hamburger */}
        <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </nav>

      {/* Hamburger Menu Content */}
      <div className={`menu ${menuOpen ? 'open' : ''}`}>
        <span className="close-button" onClick={closeMenu}>X</span>
        <ScrollLink to="aboutme" smooth={true} duration={800} onClick={closeMenu}>About</ScrollLink>
        <ScrollLink to="projects" smooth={true} duration={800} onClick={closeMenu}>Projects</ScrollLink>
        <ScrollLink to="lifeupdate" smooth={true} duration={800} onClick={closeMenu}>Blog</ScrollLink>
      </div>

      <div className="content">
        <Element name="home" className="home-section">
          <Home />
        </Element>
        <Element name="aboutme" className="aboutme-section">
          <AboutMe />
        </Element>
        <Element name="projects" className="projects-section">
          <Projects />
        </Element>
        <Element name="lifeupdate" className="lifeupdate-section">
          <LifeUpdate />
        </Element>
        <Element name="contactme" className="contactme-section">
          <ContactMe />
        </Element>
      </div>
    </div>
  );
}

export default App;